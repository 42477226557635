@import 'common';
@import 'styles/lib/include-media.scss';

html,
body {
  margin: 0;
  padding: 0;
  font-family: $font-poppins;
}

button,
input {
  outline: none;
}
